import { useContext } from 'react';
import type { IntlShape } from 'react-intl';
import { useIntl } from 'react-intl';
import IntlHelper from '@aurora/shared-utils/helpers/i18n/IntlHelper';
import AppContext from './context/AppContext/AppContext';

interface I18n {
  /**
   * The Intl object.
   */
  intl: IntlShape;

  /**
   * A shortcut to the intl "formatDate" function.
   */
  formatDate: Function;

  /**
   * A "formatRelativeTime" function.
   */
  formatRelativeTime: Function;

  /**
   *  Function to get date in absolute format.
   */
  formatAbsoluteDate: Function;

  /**
   *  Function to get date and time in absolute format.
   */
  formatAbsoluteDateTime: Function;

  /**
   *  Function to get date and time in absolute format for Occasion.
   */
  formatAbsoluteDateTimeForOccasion: Function;

  /**
   *  To get the date in user preferred date format.
   */
  getUserPreferredDateFormat: Function;
}

/**
 * The enum for date display formats
 */
export enum dateDisplayFormats {
  MM_DD_YYYY = 'MM-dd-yyyy',
  DD_MM_YYYY = 'dd-MM-yyyy',
  MMM_DD_YYYY = 'MMM dd, yyyy',
  MMMM_DD_YYYY = 'MMMM dd, yyyy'
}

/**
 *  Function to render date format when absolute date style is selected
 *
 * @param dateDisplayFormat - the format of the date
 * @param time - time from the component
 * @param formatDate - the format date method
 */
function renderUserPreferredDateFormat(
  dateDisplayFormat: string,
  time: string | number,
  formatDate
): string {
  switch (dateDisplayFormat) {
    case dateDisplayFormats.MM_DD_YYYY: {
      const value = formatDate(time, {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      });
      return value.toString().replaceAll('/', '-');
    }
    case dateDisplayFormats.DD_MM_YYYY: {
      const date = new Date(time as string);
      const dateValue = new Intl.DateTimeFormat('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      }).format(date);
      return dateValue.toString().replaceAll('/', '-');
    }
    case dateDisplayFormats.MMMM_DD_YYYY: {
      return formatDate(time, { month: 'long', day: '2-digit', year: 'numeric' });
    }
    case dateDisplayFormats.MMM_DD_YYYY: {
      return formatDate(time, {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
      });
    }
    default: {
      return formatDate(time, {
        month: 'short',
        day: '2-digit',
        year: 'numeric'
      });
    }
  }
}

/**
 *  The function to render relative time
 *
 * @param time - The time
 * @param intl - the intl object
 */
function renderRelativeTime(time: number | string, intl: IntlShape) {
  const parsedTime: number = typeof time === 'string' ? Date.parse(time) : time;
  return IntlHelper.niceRelativeTime(intl, parsedTime);
}

/**
 * Hook to provide intl date and time functions .
 *
 * @author Dolan Halbrook
 */
export default function useDateTime(): I18n {
  const intl = useIntl();
  const { formatDate } = intl;
  const {
    authUser: {
      profileSettings: { dateDisplayStyle, dateDisplayFormat }
    }
  } = useContext(AppContext);

  return {
    intl,
    formatDate,
    formatRelativeTime: (time: number | string, unit?: Intl.RelativeTimeFormatUnit): string => {
      const parsedTime: number = typeof time === 'string' ? Date.parse(time) : time;
      return unit
        ? intl.formatRelativeTime(parsedTime, unit)
        : IntlHelper.niceRelativeTime(intl, parsedTime);
    },
    formatAbsoluteDate: (time: number | string): string => {
      return formatDate(time, {
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      });
    },
    formatAbsoluteDateTime: (time: number | string): string => {
      return formatDate(time, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });
    },
    formatAbsoluteDateTimeForOccasion: (time: number | string, timezone: string) => {
      return formatDate(time, {
        timeZone: timezone,
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        timeZoneName: 'short'
      });
    },
    getUserPreferredDateFormat: (
      time: number | string,
      dateDisplayValue: string = dateDisplayFormat.value,
      dateDisplayStyleValue: string = dateDisplayStyle.value
    ) => {
      if (dateDisplayStyleValue === 'true') {
        return renderRelativeTime(time, intl);
      } else {
        return renderUserPreferredDateFormat(dateDisplayValue, time, formatDate);
      }
    }
  };
}
