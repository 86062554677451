import dynamic from 'next/dynamic';

enum MessageActionMenuIcon {
  GEAR = 'gear',
  ELLIPSIS = 'ellipsis'
}

export { MessageActionMenuIcon };

const MessageActionMenu = dynamic(() => import('./MessageActionMenu'), { ssr: false });

export default MessageActionMenu;
