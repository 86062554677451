import type { OccasionData } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import useTranslation from '../../useTranslation';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import Icons from '@aurora/shared-client/public/static/graphics/processed/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import localStyles from './OccasionProgressBadge.module.pcss';
import ThemeContext from '@aurora/shared-client/components/context/ThemeContext/ThemeContext';
import useSafeColor from '@aurora/shared-client/components/useSafeColor';
import type { CSSPropertiesWithVars } from '@aurora/shared-client/helpers/styles/CSSPropertiesWithVarsHelper';
import usePreferredTimezone from '@aurora/shared-client/components/usePreferredTimezone';
import useDateTime from '@aurora/shared-client/components/useDateTime';
import {
  EventStatus,
  getEventStatus,
  occasionImminentOrOngoing,
  oneDayInMs,
  oneHourInMs
} from '../../../helpers/util/OccasionTimeHelper';

interface Props {
  /**
   * Occasion data for which progress badge to be displayed.
   */
  occasionData: OccasionData;
  /**
   * Filters events to the specified status, otherwise all statuses are shown.
   */
  statuses?: EventStatus[];
  /**
   * Whether to show fill time text.
   */
  showFullTimeText?: boolean;
  /**
   * Class name(s) to be applied to the badge.
   */
  className?: string;
}

/**
 * Occasion Progress Badge
 *
 * @author Willi Hyde
 */

const OccasionProgressBadge: React.FC<React.PropsWithChildren<Props>> = ({
  occasionData,
  statuses = [],
  showFullTimeText = false,
  className
}) => {
  const cx = useClassNameMapper(localStyles);
  const { formatRelativeTime, formatAbsoluteDateTimeForOccasion } = useDateTime();
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.OCCASION_PROGRESS_BADGE
  );

  const { theme } = useContext(ThemeContext);
  const occasionColor = theme?.coreTypes?.occasionColor;
  const safeColorCallback = useSafeColor();
  const occasionTextColor = safeColorCallback(occasionColor).isDark()
    ? theme?.yiq?.light
    : theme?.yiq?.dark;
  const occasionTextStyles: CSSPropertiesWithVars = {
    '--lia-d-occasion-text-color': occasionTextColor
  };

  const { data: timezoneData, loading: timezoneLoading } = usePreferredTimezone();

  if (!occasionData || timezoneLoading || textLoading) {
    return null;
  }

  const { startTime } = occasionData;
  const startDateTime = new Date(startTime).getTime();

  // User time zone
  const userPreferredTimeZone = timezoneData?.self?.profileSettings?.timeZone?.value;

  // Status of the event
  const status = getEventStatus(occasionData);

  /**
   * Gets the text used for the badge
   *
   * @returns - Text for the badge or null
   */
  function getEventCountdownText(): string | null {
    const timeDifference = startDateTime - Date.now();

    if (status === EventStatus.HAPPENING_LATER) {
      return null;
    } else if (status === EventStatus.WITHIN_THREE_DAYS) {
      const daysRemaining = Math.floor(timeDifference / oneDayInMs);
      const hoursRemaining = Math.floor((timeDifference % oneDayInMs) / oneHourInMs);

      if (daysRemaining >= 1) {
        return formatRelativeTime(daysRemaining, 'days');
      } else {
        return formatRelativeTime(hoursRemaining, 'hours');
      }
    }

    return formatMessage(`occasion.${status}`);
  }

  const isImminentOrOngoing = occasionImminentOrOngoing.includes(status);

  if (statuses.includes(status) || statuses.length === 0) {
    return (
      <div
        className={cx(
          'lia-occasion-progress-badge',
          status === EventStatus.HAPPENING_LATER ? 'lia-g-column-gap-10' : 'lia-g-column-gap-20'
        )}
        data-testid="OccasionProgressBadge"
      >
        <div
          className={cx(
            'lia-badge',
            { 'lia-is-imminent-or-ongoing': isImminentOrOngoing },
            { 'lia-has-ended': status === EventStatus.HAS_ENDED },
            className
          )}
          style={occasionTextStyles}
        >
          <Icon icon={Icons.ContentOccasionIcon} size={IconSize.PX_16} className={cx('lia-icon')} />
          {getEventCountdownText()}
        </div>
        {showFullTimeText && (
          <small className={cx('text-muted')}>
            {formatAbsoluteDateTimeForOccasion(startDateTime, userPreferredTimeZone)}
          </small>
        )}
      </div>
    );
  }

  return null;
};

export default OccasionProgressBadge;
